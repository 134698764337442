import { mapGetters } from "vuex";
import pageheader from "../../common/pageheader";
import utils from "../../../utils/utils";
import moment from "moment";
import hourstoolsService from "../../../api/hourstools.service";
import refresh from "../../secured/SupportToolsDialogs/RefreshButton";
import XLSX from "xlsx";
import UploadDialog from "../../secured/SupportToolsDialogs/UploadDialog";
import LogsDialog from "../../secured/SupportToolsDialogs/LogsDialog";
import ExecuteDialog from "../../secured/SupportToolsDialogs/ExecuteDialog";
export default {
  name: 'hours-update-tool',
  components: { XLSX,pageheader, refresh, UploadDialog, LogsDialog, ExecuteDialog },
  props: [],
  data () {
    return {
      input: {},
      page: 0,
      files: [],
      HoursToolsList: [],
      showUpload: false,
      isLoading: false,
      HoursListSearch: "",
      apiToken: "",
      customerId: "",
      headers: [
        { text: "S.No", value: "sno", sortable: false },
        { text: "File Name", value: "fileName" },
        { text: "Date Time", value: "startDate" },
        { text: "Status", value: "status" },
        {
          text: "Actions",
          value: "view",
          align: "center",
          width: "240px",
          sortable: false,
        },
      ],
      Logsdialog: false,
      viewLogs: [],
      LogsSearch: "",
      loadingLogs: false,
      fileName: "",
      viewLogheaders: [
        {text: 'S.No', value: 'sno', align: "center", sortable: false},
        {text: 'Serial Number', value: 'serialNumber'},
        {text: 'Asset Name', value: 'name'},
        {text: 'Run Time', value: 'runtime'},
        {text: 'Value in TU', value: 'valueInTU'},
        {text: 'Value From File', value: 'valueFromFile'},
        {text: 'Date From File', value: 'dateFromFile'},
        {text: 'Utilization Since Column E', value: 'utilizationSinceColumnE'},
        {text: 'Updated Hour Meter', value: 'updatedHourMeter'},
        {text: 'Is Persisted', value: 'isPersisted'},
        {text: 'Error Message', value: 'errorMessage'}
      ],
      HoursExcel: [],
      HoursToolsListExcel:[],
      Executedialog: false,
      SelectedTool: "",
      selectedId: "",
      fileLoader: false,
      Id: "",
      companyToken: "",
      dialog: "",
    }
  },
  computed: {
    ...mapGetters(["roles", "userId","customerToken","customerReferenceId","companyName","reportId"])
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        const format = "MM-DD-YYYY";
        return moment(value).format(format);
      }
    },
  },
  mounted () {
    this.input = utils.getInputContent("hoursUpdateTool", this.roles);
    this.Id = this.companyName;
    this.companyToken = this.$store.getters.customerToken;
    this.customerId = this.$store.getters.customerReferenceId;
    this.GetHoursToolsList();
  },
  methods: {
    //Upload Dialog Code
    openUploadDialog() {
      this.files = [];
      this.$refs.uploadModal.showModal();
    },
    closeUploadDialog() {
      this.$refs.uploadModal.closeUploadDialog();
    },
     //get files from child component
    getList(files){
      this.files = [];
      this.files = files;
    },
       //Upload File
       async UploadFile() {
        this.fileLoader = true;
        let payload = {
          userId: this.userId,
          fileInfo: this.files[0],
        };
        let headerpayload = {
          customerId: this.reportId,
          // apiToken: this.companyToken,
        };
        if (this.files[0] == null) {
          this.$toast.error("Please select a file ");
        } else if (!this.files[0].fileName.includes(".xlsx")) {
          this.$refs.dropzone.removeAllFiles();
          this.$toast.error("please select only files with extension .xlsx");
        } else {
          try {
            this.selecteditem = this.selectedTitle;
            this.$toast.success("File is being processed. Please wait.");
            this.closeUploadDialog();
            let apiResult = await hourstoolsService.UploadHoursTools(
              payload,
              headerpayload
            );
            
            if (apiResult.isSuccess == true) {
              this.closeUploadDialog();
              this.fileLoader = false;
              this.$toast.success(apiResult.systemMessage);
              this.GetHoursToolsList();
            }
          } catch (err) {
            this.$toast.error("Unable to upload file" + err);
            this.fileLoader = false;
            this.closeUploadDialog();
          }
        }
      },

    //Get HoursTools List
    async GetHoursToolsList() {
      this.isLoading = true;
      let payload = {
        customerId: this.reportId,
        // apiToken: this.companyToken,
      };
      try {
        let response = await hourstoolsService.GetHoursTools(payload);
        if (response) {
          this.isLoading = false;
          this.HoursToolsList = [];
          this.HoursToolsList = response.map((d, index) => ({
            ...d,
            sno: index + 1,
            index: false,
          }));
        }
      } catch (err) {
        this.$toast.error("Unable to load the List" + err);
        this.isLoading = false;
      }
    },
    getColor(status) {
      if (status == "Completed") return "green";
      else if (status == "LogsCreated") return "blue";
      else if (status == "ExecutionInProgress") return "#013220";
      else if (status == "InProgress") return "orange";
      else if (status == "Failed") return "red";
      else return "black";
    },
    //Get View Logs
    async openHoursLogsDialog(item) {
      this.$refs.LogModal.showModal();
      this.viewLogs = [];
      this.loadingLogs = true;
      this.Logsdialog = true;
      this.fileName = item.fileName;
      let toolId = item.toolId;
      let headerpayload = {
        customerId: this.reportId,
        // apiToken: this.companyToken,
        toolId: toolId
      };
      try {
        let response = await hourstoolsService.GetHoursById(toolId, headerpayload);
        if (response) {
          this.loadingLogs = false;
          this.HoursToolsListExcel = response;
          this.HoursToolsListExcel = this.HoursToolsListExcel.map(function(item) {
            delete item.id;
            return item;
          });
          this.viewLogs = response.map((d, index) => ({
            ...d,
            sno: index + 1,
          }));
          this.excelfilename = this.excelfilename + "_Output";
          this.sheetName = this.excelfilename.replace(".xlsx", "");
        }
      } catch (err) {
        this.$toast.error("Unable to Execute" + err);
        this.loadingLogs = false;
      }
    },
    //Execute Logs
    openExecuteDialog(toolId,index) {
      this.$refs.ExecuteModal.showModal();
      this.SelectedTool = toolId;
      console.log(index)
      this.selectedId = index;
    },
    closeExecuteDialog() {
      this.$refs.ExecuteModal.closeExecuteDialog();
    },
      async ShowExecutedLogs(id , index){
        var t = this;
        console.log(index);
        let payload = {
          userId: this.userId,
          toolId: id,
          customerId: this.reportId

        }
        let headerpayload = {
          customerId: this.reportId,
          // apiToken: this.companyToken,
        }
        try {
          let response = await hourstoolsService.ExecuteHoursTools(payload,headerpayload);
          if (response.isSuccess == true) {
          this.$toast.success("Execution in progress!!");
          t.HoursToolsList[index-1]["index"] = true;
          this.closeExecuteDialog();
          }
          else{
            t.HoursToolsList[index-1]["index"] = false;
            this.closeExecuteDialog();
            this.$toast.error("Unable to Execute Please try again after some time");
          }
        } catch (err) {
          this.$toast.error("Unable to Execute" + err);
          this.closeExecuteDialog();
          this.loading = false;
        }
        
      },
      OnHoursExport () { 
        this.HoursExcel = [];
        this.HoursExcel.push([
          "Serial Number",
          "Asset Name",
          "Run Time",
          "Value in TU",
          "Value From File",
          "Date From File",
          "Utilization Since Column E",
          "Updated Hour Meter",
          "Is persisted",
          "Error Message"
        ]);
        var ws = XLSX.utils.aoa_to_sheet(this.HoursExcel);
        XLSX.utils.sheet_add_json(ws, this.HoursToolsListExcel, {
          header: [
            "serialNumber",
            "name",
            "runtime",
            "valueInTU",
            "valueFromFile",
            "dateFromFile",
            "utilizationSinceColumnE",
            "updatedHourMeter",
            "isPersisted",
            "errorMessage"
          ],
          skipHeader: true,
          origin: "A2",
        });
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Hours Update Logs");
         this.HoursExcel = [];
         XLSX.writeFile(wb, "Hours Update Logs.xlsx");
      },
  }
}


