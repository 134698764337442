// import axios from "axios";
// import env from "../config";
import axios from "../api/axios";
// const client = axios.create({
//   baseURL: env.HOURS_TOOLS_ENDPONT,
//   json: true,
// });

export default {
  async execute(method, resource, data,requestheader = {}) {
    return axios.hoursToolsEndPoint({
      method,
      url: resource,
      data,
      headers: requestheader
    }).then((req) => {
      return req.data;
    });
  },
  UploadHoursTools(payload,headerpayload){
    let requestheader = headerpayload
    return this.execute("post", `/HoursUpdate`,payload,
    requestheader
    );
  },
  GetHoursTools(payload){
    return this.execute("get", `/HoursUpdate/Logs`,null,payload);
  },
  ExecuteHoursTools(payload,headerpayload){
    return this.execute("put", `/HoursUpdate/Execute`,payload,headerpayload);
  },
  GetHoursById(toolId,headerpayload){
    return this.execute("get", `/HoursUpdate/Log/${toolId}`,null,headerpayload);
  },
};
